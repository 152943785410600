import { notification, Typography } from "antd";
import { groupBy, sortBy, times } from "lodash";
import moment from "moment";
import { User } from "redux/auth/auth.slice";
import { getDefaultFooterValues } from "screens/designStudio/editor/instantExperience/ButtonComponent";
import { getDefaultCarouselValues } from "screens/designStudio/editor/instantExperience/CarouselComponent";
import { getDefaultPhotoValues } from "screens/designStudio/editor/instantExperience/ImageComponent";
import { getDefaultProductSetValues } from "screens/designStudio/editor/instantExperience/ProductSetComponent";
import { getDefaultVideoValues } from "screens/designStudio/editor/instantExperience/VideoComponent";
import { ImportInstantExperienceData } from "screens/designStudio/library/instantExperiences/InstantExperiencesImportDrawer";
import {
  AdCategory,
  AdditionalCardPlacement,
  ElementType,
  IAd,
  ICard,
  ICarouselElement,
  IFooterElement,
  IInstantExperience,
  InstantExperienceBodyElement,
  InstantExperienceElementStyle,
  IPhotoElement,
  IProductSetElement,
  IVideoElement,
  IVisuals,
  QcStatus,
} from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { getErrorMessage, raise } from "utils/errorMessage";
import { getEnvVar } from "utils/helpers";
import { importAsset, IUploadMediaUrls } from "utils/uploadMedia";
import uuid from "uuid";
import { callToActionCustomLabels } from "../constants";
import { AdType, CallToAction } from "../facebookUtils/types";
import { AdMedia } from "shared/types/uploadManagement";
import { CsvAdFormat, ImportAdData } from "./adImportDrawer/utils";
const { Text, Link } = Typography;

export const processCsvRawData = <T,>(
  csvRawData: string[][] | undefined,
  csvValidHeaders: string[],
) => {
  const isValidHeader = (header: string) => csvValidHeaders.includes(header);

  const extractValidHeaders = (csvHeaders: string[]): (keyof T)[] => {
    return csvHeaders.filter(isValidHeader) as (keyof T)[];
  };

  const csvHeaders = csvRawData?.[0] ?? [];
  const csvBody = csvRawData?.slice(1) ?? [];

  const data =
    csvBody.map(dataArray => {
      // Transform the csv data into an object with the keys of the headers
      return dataArray.reduce((acc, dataItem, index) => {
        const header = csvHeaders[index];
        if (isValidHeader(header)) {
          return { ...acc, [header]: dataItem };
        }
        return acc;
      }, {} as T);
    }) ?? [];

  const validCsvHeaders = extractValidHeaders(csvRawData?.[0] ?? []);

  return { data, validCsvHeaders };
};

export const groupInstantExperiencesDataByName = (
  instantExperiencesData: ImportInstantExperienceData[],
): [string, ImportInstantExperienceData[]][] =>
  Object.entries(
    groupBy(
      sortBy(instantExperiencesData, data => +data["Component Order Number"]),
      data => data["Instant Experience Name"],
    ),
  );

export const mapCsvDataToInstantExperience = async (
  instantExperienceName: string,
  csvComponentsData: ImportInstantExperienceData[],
): Promise<IInstantExperience> => {
  const bodyElements = await Promise.all(csvComponentsData.map(getComponent));

  return {
    id: uuid(),
    name: instantExperienceName,
    oem: csvComponentsData?.[0].Brand, // TODO validate oem
    body_elements: bodyElements,
  };
};

const elementTypeMap: Record<string, ElementType> = {
  Video: "VIDEO",
  "Image (Fit to Height Tilt to Pan)": "PHOTO",
  "Image (Fit to Width Linkable)": "PHOTO",
  "Image (Fit to Width Tap to Expand)": "PHOTO",
  Button: "BUTTON",
  "Product Set": "ELEMENT_GROUP",
  Carousel: "CAROUSEL",
};

const photoStyleMap: Record<string, InstantExperienceElementStyle> = {
  "Image (Fit to Height Tilt to Pan)":
    InstantExperienceElementStyle.FIT_TO_HEIGHT,
  "Image (Fit to Width Linkable)": InstantExperienceElementStyle.FIT_TO_WIDTH,
  "Image (Fit to Width Tap to Expand)":
    InstantExperienceElementStyle.FIT_TO_WIDTH_EXPANDABLE,
};

const getComponent = async (
  component: ImportInstantExperienceData,
): Promise<InstantExperienceBodyElement> => {
  const componentType = component["Component Type"];
  const elementType = elementTypeMap[componentType] ?? componentType;
  return (
    getComponentFunctionsMap[elementType]?.(component) ??
    getUnknownComponent(component)
  );
};

export const mapCsvDataToAd = (
  csvData: ImportAdData,
  user?: User | null,
): {
  ad: IAd;
  feedUploadPromises: Promise<PromiseSettledResult<IUploadMediaUrls>[]>;
  storyUploadPromises: Promise<PromiseSettledResult<IUploadMediaUrls>[]>;
} => {
  const adType = getAdType(csvData["Ad Format"] as CsvAdFormat);
  let cards: ICard[] | undefined;
  let feedMediaPromises: Promise<IUploadMediaUrls>[] = [];
  let storyMediaPromises: Promise<IUploadMediaUrls>[] = [];
  const visualsCustomParams: Partial<IVisuals> = [
    AdType.AIA,
    AdType.DPA,
    AdType.FTA,
  ].includes(adType as AdType)
    ? {
        primaryText: csvData["Primary Text"],
        creativeOption: "single",
        format: ["AIA Carousel", "DPA Carousel", "FTA Carousel"].includes(
          csvData["Ad Format"] as string,
        )
          ? "carousel"
          : "still",
      }
    : { postCopy: csvData["Primary Text"] };

  try {
    if (adType === AdType.Carousel) {
      const headlines: string[] = splitByNewline(csvData.Headline) ?? [];
      const descriptions: string[] =
        splitByNewline(csvData["Description"]) ?? [];

      const mediaLinks: string[] =
        splitByNewline(csvData["Media Link URL"]) ?? [];
      const storyLinks: string[] =
        splitByNewline(csvData["Media Story Link URL"]) ?? [];

      const destinationUrls: string[] = (
        splitByNewline(csvData["Destination URL"]) ?? []
      ).map(url => url.replace(/[/ ]*$/, ""));
      const callToActions = splitByNewline(csvData.CTA) ?? [];

      feedMediaPromises = mediaLinks.map(link => uploadFileToS3(link, true));
      storyMediaPromises = storyLinks.map(link => uploadFileToS3(link, true));

      const numberOfCards = Math.max(
        mediaLinks.length,
        storyLinks.length,
        headlines.length,
        descriptions.length,
        callToActions.length,
      );

      cards = times(numberOfCards, index => {
        const ctaButtonText =
          callToActions.length === 1
            ? getCTA(callToActions[0])
            : getCTA(callToActions[index]);

        const headline = headlines[index] ?? "";
        const description = descriptions[index] ?? "";
        const destinationUrl = destinationUrls[index];

        const hasStoryLink: string | undefined = storyLinks[index];

        return {
          headline,
          description,
          destinationUrl,
          ctaButtonText,
          thumbnail: "loading",
          additionalPlacements: hasStoryLink
            ? [
                {
                  headline,
                  description,
                  destinationUrl,
                  thumbnail: "loading",
                  size: "STORY",
                  filetype: "image",
                  imageUrl: "",
                },
              ]
            : [],
        };
      });
    } else {
      const mediaLink = csvData["Media Link URL"];
      const storyMediaLink = csvData["Media Story Link URL"];
      // convert media links to a file

      if (mediaLink) {
        feedMediaPromises = [uploadFileToS3(mediaLink, true)];
      }
      if (storyMediaLink) {
        storyMediaPromises = [uploadFileToS3(storyMediaLink, true)];
      }
    }
  } catch (_) {
    // TODO: display number of errors in the notification
  }

  const campaignMonth = getFormattedCampaignDate(csvData["Campaign Month"]);

  const visualsId = uuid();
  const inputParametersId = uuid();
  const defaultIndustry = getEnvVar<IndustryType>("INDUSTRY") || "auto";

  const parseIndustry = (industry?: string): IndustryType => {
    const validValues = ["auto", "retail", "travel", "pharma", "all"];
    const transformedIndustry = industry?.toLowerCase();
    const found = validValues.find(
      (ind): ind is IndustryType => ind === transformedIndustry,
    );

    if (found) return found;
    return defaultIndustry;
  };
  const ad: IAd = {
    id: uuid(),
    category: (csvData["Ad Category"] as AdCategory) || undefined, // check this
    platform: "facebook",
    industry: parseIndustry(csvData["Industry"]),
    createdAt: new Date().getTime(),
    createdBy: user?.email || "",
    updatedAt: new Date().getTime(),
    updatedBy: user?.email ?? "",
    qcStatus: QcStatus.DRAFT,
    type: adType as AdType,
    visualsId,
    visuals: {
      id: visualsId,
      ctaButtonText: csvData.CTA ? getCTA(csvData.CTA) : undefined,
      cards,
      headlineDescription:
        adType !== AdType.Carousel ? csvData["Description"] : undefined,
      headline: csvData.Headline,
      displayUrl: csvData["Display URL"],
      thumbnail: "loading",
      postCopy: csvData["Primary Text"] ?? "",
      additionalPlacements: csvData["Media Story Link URL"]
        ? [
            {
              thumbnail: "loading",
              size: "STORY",
              filetype: "image",
              imageUrl: "",
            },
          ]
        : [],
      ...visualsCustomParams,
    },
    inputParametersId,
    inputParameters: {
      id: inputParametersId,
      dealers: csvData["Account"] ? [csvData["Account"]] : undefined,
      name: csvData["Ad Name"],
      models: [
        {
          name: csvData["Model Name"] ?? "",
          year: csvData["Model Year"] ?? "",
        },
      ],
      oems: getBrand(csvData),
      client: csvData["Client"],
      campaignStartDate: getDateAsTimestamp(csvData["Start Date"]),
      campaignEndDate: getDateAsTimestamp(csvData["End Date"]),
      tags: csvData.Tags ? csvData.Tags.split(/ *, */) : [],
      package: csvData.Package,
      destinationUrl:
        adType !== AdType.Carousel
          ? csvData["Destination URL"]?.replace(/[/ ]*$/, "")
          : undefined,
      utm: csvData["UTM"],
      campaignMonth,
    },
  };

  return {
    ad,
    feedUploadPromises: Promise.allSettled(feedMediaPromises),
    storyUploadPromises: Promise.allSettled(storyMediaPromises),
  };
};

const getBrand = (csvData: ImportAdData) => {
  const brand = csvData["Brand"] ?? csvData["OEM"];

  return brand ? [brand] : undefined;
};

export const getAdsInfoFromCsvData = async (csvData: ImportAdData) => {
  const adType = getAdType(csvData["Ad Format"] as CsvAdFormat);
  if (adType === AdType.Carousel) {
    const mediaLinks: string[] =
      splitByNewline(csvData["Media Link URL"]) ?? [];
    return Promise.all(mediaLinks.map(getMediaInfo));
  } else {
    const mediaLink = csvData["Media Link URL"];
    if (mediaLink) {
      // convert media link to a file
      const asset = await getMediaInfo(mediaLink);

      return [asset];
    }
  }
};

const getUnknownComponent = (component: ImportInstantExperienceData) => {
  return {
    id: uuid(),
    name: component["Name (Optional)"],
    element_type: component["Component Type"],
  };
};

const getVideoComponent = async (
  component: ImportInstantExperienceData,
): Promise<IVideoElement> => {
  const asset = component["Media Link URL"]
    ? await uploadFileToS3(component["Media Link URL"], true)
    : undefined;

  const defaultVideoValues = getDefaultVideoValues();
  return {
    ...defaultVideoValues,
    name: component["Name (Optional)"] || defaultVideoValues.name,
    thumbnail: asset?.thumbnail,
    url: asset?.videoUrl,
  };
};

const getImageComponent = async (
  component: ImportInstantExperienceData,
): Promise<IPhotoElement> => {
  const asset = component["Media Link URL"]
    ? await uploadFileToS3(component["Media Link URL"], false)
    : undefined;

  const defaultPhotoValues = getDefaultPhotoValues();
  return {
    ...defaultPhotoValues,
    name: component["Name (Optional)"] || defaultPhotoValues.name,
    url: asset?.thumbnail,
    style: photoStyleMap[component["Component Type"]],
  };
};

const getFooterComponent = (
  component: ImportInstantExperienceData,
): IFooterElement => {
  const defaultFooterValues = getDefaultFooterValues();

  return {
    ...defaultFooterValues,
    id: uuid(),
    name: component["Name (Optional)"] || defaultFooterValues.name,
    child_elements: [
      {
        ...defaultFooterValues.child_elements[0],
        name: component["Name (Optional)"] || defaultFooterValues.name,
      },
    ],
  };
};

const getProductSetComponent = (
  component: ImportInstantExperienceData,
): IProductSetElement => {
  const defaultProductSetValues = getDefaultProductSetValues();
  return {
    ...defaultProductSetValues,
    name: component["Name (Optional)"] || defaultProductSetValues.name,
    item_headline: component["Product Headline (Optional)"],
    item_description: component["Product Description (Optional)"],
  };
};

const getCarouselComponent = async (
  component: ImportInstantExperienceData,
): Promise<ICarouselElement> => {
  const mediaLinks: string[] =
    splitByNewline(component["Media Link URL"]) ?? [];

  const uploadedMediaList = await Promise.all(
    mediaLinks.map(link => uploadFileToS3(link, false)),
  );

  const defaultCarouselValues = getDefaultCarouselValues();
  const defaultPhotoValues = getDefaultPhotoValues();

  return {
    ...defaultCarouselValues,
    name: component["Name (Optional)"] || defaultCarouselValues.name,
    child_elements: uploadedMediaList.map(
      (asset): IPhotoElement => ({
        ...defaultPhotoValues,
        url: asset.thumbnail,
      }),
    ),
  };
};

const getComponentFunctionsMap: Record<
  ElementType,
  (
    component: ImportInstantExperienceData,
  ) => Promise<InstantExperienceBodyElement> | InstantExperienceBodyElement
> = {
  VIDEO: getVideoComponent,
  PHOTO: getImageComponent,
  FOOTER: getFooterComponent,
  ELEMENT_GROUP: getProductSetComponent,
  CAROUSEL: getCarouselComponent,
  BUTTON: getFooterComponent,
};

const csvAdFormatToAdTypeMap: Record<CsvAdFormat, AdType> = {
  Motion: AdType.Video,
  Video: AdType.Video,
  Still: AdType.Still,
  Carousel: AdType.Carousel,
  "AIA Carousel": AdType.AIA,
  "AIA Still": AdType.AIA,
  "DPA Carousel": AdType.DPA,
  "DPA Still": AdType.DPA,
  Collection: AdType.Collection,
  "Instant Experience": AdType.InstantExperience,
  Canvas: AdType.InstantExperience,
  "FTA Carousel": AdType.FTA,
  "FTA Still": AdType.FTA,
};

export const getAdType = (csvAdFormat: string): AdType | string => {
  const csvAdFormatWithoutExtraSpaces = removeExtraSpaces(csvAdFormat);

  const adTypeEntry = Object.entries(csvAdFormatToAdTypeMap).find(
    ([key]) =>
      csvAdFormatWithoutExtraSpaces?.toLowerCase() === key.toLowerCase(),
  );

  return adTypeEntry?.[1] ?? csvAdFormatWithoutExtraSpaces;
};

const verifyCtaByEnum = (value: string, expectedCTA: CallToAction) => {
  const match = new RegExp(
    "^" + expectedCTA.replace(/_/g, " ") + "$",
    "i",
  ).test(value);

  return match;
};

const verifyCtaByCustomText = (value: string, expectedString: string) => {
  return value.toLocaleLowerCase() === expectedString.toLocaleLowerCase();
};

export const getCTA = (csvCTA: string): CallToAction | undefined => {
  if (!csvCTA) return;

  const csvCTAWithoutExtraSpaces = removeExtraSpaces(csvCTA);

  for (const cta of Object.values(CallToAction)) {
    let isValidCta = false;

    if (cta === CallToAction.BOOK_TRAVEL) {
      isValidCta = verifyCtaByCustomText(
        csvCTAWithoutExtraSpaces,
        callToActionCustomLabels[cta],
      );
    } else {
      isValidCta = verifyCtaByEnum(csvCTAWithoutExtraSpaces, cta);
    }

    if (isValidCta) {
      return cta;
    }
  }
};

export const getFormattedCampaignDate = (dateString?: string) => {
  if (!dateString) return;

  const date = moment(
    dateString,
    ["YYYY-MM-DD", "MMM YYYY", "MM/DD/YYYY"],
    false,
  );

  if (!date.isValid()) return;
  return date.format("MMM YYYY");
};

export const getDateAsTimestamp = (dateString?: string) => {
  if (!dateString) return;

  const date = moment(
    dateString,
    ["YYYY-MM-DD", "MM/DD/YYYY", "MM/DD/YY"],
    false,
  );

  if (!date.isValid()) return;

  return date.valueOf().toString();
};

const splitByNewline = (value?: string) =>
  value?.split(/\r?\n/).filter(Boolean);

/**
 * Removes duplicated spaces between words and trims the value
 */
const removeExtraSpaces = (value: string) => value?.replace(/\s+/g, " ").trim();

/** Uploads dropbox file to S3 (and facebook in case it's a video) */
const uploadDropboxFileToS3 = async (
  mediaLink: string,
  createFacebookAdVideo: boolean,
): Promise<IUploadMediaUrls> => {
  try {
    const dataLinkURL = mediaLink.includes("www.dropbox.com")
      ? mediaLink.replace("?dl=0", "").replace("www.", "dl.")
      : mediaLink;
    return await importAsset(dataLinkURL, createFacebookAdVideo);
  } catch (error) {
    return {
      thumbnail: "error",
    };
  }
};

const uploadFileToS3 = async (
  mediaLink: string,
  createFacebookAdVideo: boolean,
): Promise<IUploadMediaUrls> => {
  // This is only for legacy support
  if (mediaLink.includes("www.dropbox.com")) {
    return uploadDropboxFileToS3(mediaLink, createFacebookAdVideo);
  }

  try {
    return await importAsset(mediaLink, createFacebookAdVideo);
  } catch (error) {
    const e = error as Error;
    if (e.message.includes("403")) {
      return {
        thumbnail: "403",
      };
    }

    return {
      thumbnail: "error",
    };
  }
};

const getMediaInfo = async (mediaLink: string) => {
  try {
    const dataLinkURL = mediaLink.includes("www.dropbox.com")
      ? mediaLink.replace("?dl=0", "").replace("www.", "dl.")
      : mediaLink;
    const blob = await getFileBlob(dataLinkURL);
    return {
      file: blob,
      filename: dataLinkURL,
      type: blob.type,
      size: blob.size,
    };
  } catch (error) {
    const message = getErrorMessage(error);
    notification.error({
      message: (
        <Text data-cy="import-media-error-message">
          {message}:{" "}
          <Link href={mediaLink} target="_blank">
            {mediaLink}
          </Link>
        </Text>
      ),
      placement: "bottomLeft",
      duration: 10,
    });

    return {
      file: null,
      size: 0,
    };
  }
};

const getFileBlob = async (linkURL: string): Promise<Blob> => {
  const response = await fetch(linkURL);
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error("File not found");
    }
    throw new Error("Failed to download file");
  }
  return response.blob();
};

const getCarouselAdditionalPlacements = (
  media: IUploadMediaUrls,
  ad: IAd,
  card: ICard,
): AdditionalCardPlacement[] => {
  if (!media || !card.additionalPlacements?.[0]) return [];

  const baseDetails = {
    headline: card.additionalPlacements[0].headline,
    description: card.additionalPlacements[0].description,
    destinationUrl: card.additionalPlacements[0].destinationUrl ?? "",
    size: "STORY",
  } as const;

  if (media.videoUrl) {
    return [
      {
        ...baseDetails,
        assetId:
          media.assetId ??
          raise(`Asset ID is missing for ad ${ad.inputParameters.name}`),
        thumbnail: media.thumbnail || "",
        videoUrl:
          media.videoUrl ??
          raise(`VideoURL is missing for ad ${ad.inputParameters.name}`),
        filetype: "video",
      },
    ];
  }

  return [
    {
      ...baseDetails,
      thumbnail: media.thumbnail || "",
      imageUrl: media.thumbnail || "",
      filetype: "image",
    },
  ];
};

const getAdditionalPlacements = (
  media: IUploadMediaUrls[],
  ad: IAd,
): AdMedia[] => {
  if (!media || media.length < 1) return [];

  const mediaType = media[0]?.videoUrl ? "video" : "image";

  if (mediaType === "video") {
    return [
      {
        assetId:
          media[0]?.assetId ??
          raise(`Asset ID is missing for ad ${ad.inputParameters.name}`),
        thumbnail: media[0]?.thumbnail || "",
        videoUrl:
          media[0]?.videoUrl ??
          raise(`VideoURL is missing for ad ${ad.inputParameters.name}`),
        size: "STORY",
        filetype: "video",
      },
    ];
  }

  return [
    {
      thumbnail: media[0]?.thumbnail || "",
      imageUrl: media[0]?.thumbnail || "",
      size: "STORY",
      filetype: "image",
    },
  ];
};

export const updateAdWithLoadedMedia = (
  feedMedia: IUploadMediaUrls[],
  storyMedia: IUploadMediaUrls[],
  ad: IAd,
): Partial<IAd> => {
  if (ad.type == AdType.Carousel) {
    return {
      visuals: {
        ...ad.visuals,
        cards: ad.visuals?.cards?.map((card, index) => ({
          ...card,
          assetId: feedMedia[index]?.assetId,
          thumbnail: feedMedia[index]?.thumbnail || "",
          videoUrl: feedMedia[index]?.videoUrl,
          additionalPlacements: getCarouselAdditionalPlacements(
            storyMedia[index],
            ad,
            card,
          ),
        })),
        thumbnail: feedMedia[0]?.thumbnail || "",
        assetId: feedMedia[0]?.assetId,
        videoUrl: feedMedia[0]?.videoUrl,
      },
    };
  }

  return {
    visuals: {
      ...ad.visuals,
      assetId: feedMedia[0]?.assetId,
      thumbnail: feedMedia[0]?.thumbnail || "",
      videoUrl: feedMedia[0]?.videoUrl,
      additionalPlacements: getAdditionalPlacements(storyMedia, ad),
    },
  };
};
