import { datadogRum } from "@datadog/browser-rum";
import { User } from "redux/auth/auth.slice";

export const isDataDogEnabled = process.env.REACT_APP_ENV === "prd";

export const initDatadog = () => {
  if (!isDataDogEnabled) return;

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_ID!,
    clientToken: process.env.REACT_APP_DD_RUM_TOKEN!,
    site: "datadoghq.com",
    env: process.env.REACT_APP_ENV!,
    service: process.env.REACT_APP_DD_RUM_SERVICE!,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enableExperimentalFeatures: ["clickmap"],
    defaultPrivacyLevel: "allow",
  });
};

export const setDatdogSessionUser = (user: User) => {
  if (!isDataDogEnabled) return;

  const { firstName, lastName } = user.user_metadata ?? {};
  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : user.name;

  datadogRum.setUser({
    id: user.sub,
    email: user.email,
    name: displayName,
  });
};
