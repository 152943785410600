const config: Partial<MGConfig> = {
  active: true,
  integrations: true,
};

const dev: Partial<MGConfig> = {
  integrations: true,
};

const stg: Partial<MGConfig> = {};

const prd: Partial<MGConfig> = {};

export default {
  ...config,
  ...{
    dev,
    stg,
    prd,
  }[process.env.REACT_APP_ENV!],
};
